import {
  handleError, isEmptyObject,
} from '@/utils/utils';
import { mapMutations } from 'vuex';
import * as types from '@/store/mutation-types';
import RouteGeneratorMixin from './RouteGeneratorMixin';

export default {
  mixins: [RouteGeneratorMixin],
  data() {
    return {
      errors: {},
      dialog: false,
      loading: false,
      isEdit: false,
      formValidity: true,
      footerProps: {
        'disable-items-per-page': true,
        'show-first-last-page': true,
      },
    };
  },

  computed: {
    formTitle() {
      return !this.isEdit ? 'Add Data' : 'Edit Data';
    },

    // create object form to post
    formData() {
      return Object.keys(this.form).reduce((acc, cur) => {
        acc[cur] = this.form[cur];
        return acc;
      }, {});
    },
  },

  watch: {
    dialog(val) {
      // Reset errors when open modal dialog
      this[types.ERROR](null);

      if (val && !this.isEmptyObject(this.item)) {
        this.isEdit = true;
        this.form = { ...this.item };
      } else {
        this.isEdit = false;
        this.resetForm();
      }

      if (!val) {
        this.closeDialog();
      }
    },
  },

  methods: {
    ...mapMutations([types.ERROR]),

    // can be called by this
    handleError,
    isEmptyObject,

    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        this.resetForm();
        this.isEdit = false;
      }, 300);
    },

    async saveItem() {
      this.$refs.form.validate();
      if (!this.formValidity) return;

      // Reset errors save item
      this[types.ERROR](null);

      this.loading = true;
      if (this.isEdit) {
        // edit data
        try {
          const res = await this.updateData();
          if (res.status === 200) {
            // change corresponding items in array
            // Object.assign(this.items[this.xxx], res.data.data);
            this.closeDialog();
            this.$emit('reload-data', res.data.data);
            this.$toast.success('Success Edit Data');
          }
        } catch (error) {
          handleError(error);
          this.$toast.error('Failed Edit Data');
        }
      } else {
        // add data
        try {
          const res = await this.addData();
          if (res.status === 201) {
            // add new items to array
            // this.items.push(res.data.data);
            // increment total items
            // this.meta.total += 1;
            this.closeDialog();
            this.$emit('reload-data', res.data.data);
            this.$toast.success('Success Add Data');
          }
        } catch (error) {
          handleError(error);
          this.$toast.error('Failed Add Data');
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 300);
    },

    setPropertyToNull(obj, key) {
      // set to null, when clearable clicked
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = null;
      }, 0);
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.form = { ...this.defaultForm };
    },
  },
};
