import {
  isEmptyObject,
} from '@/utils/utils';

export default {
  methods: {
    routeGenerator({ page = 1, reset = false } = {}) {
      // generate route for push page (filter and pagination feature)
      const routerPath = {
        path: this.$route.path,
        query: {
          page,
        },
      };

      // loops query based on filter object keys
      if (this.filter && !isEmptyObject(this.filter)) {
        const queries = Object.keys(this.filter);
        queries.forEach((q) => {
          if (q === 'per_page') {
            routerPath.query.per_page = reset ? 10 : this.filter.per_page;
          } else if (q === 'page') {
            routerPath.query.page = page;
          } else {
            routerPath.query[q] = reset ? null : this.filter[q];
          }
        });
      }

      return routerPath;
    },
  },
};
